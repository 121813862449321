/* eslint-disable react/no-unknown-property */
import React from 'react';
// import { MenuItem } from "react-bootstrap";
// import moment from 'moment';
import PropTypes from 'prop-types';
// import Datetime from 'react-datetime';
import { connect } from 'react-redux';
// import { UpdateUserDetails } from '../../../../src/redux/actions';
import { StyledHeader } from '../PeopleHomeV2/EditUserDetailsPopup/styles';
import { ActivityBar, ActivityBarFill, ActivityContainer, ContainerV2, CountDiv, FirstRowCard, FirstRowCardV2, LastImprovementRow, ShowPoints, StyledBody, StyledModalV3, WbaCardContainer, WbaPointsV2, YearDisplay } from './styles';
import { getUserWellbeingAssessmentDetails, getUserWellbeingAssessmentDetailsV2, setSelectedYear } from '../../redux/actions';
import { CustomYearDropDown } from '../ReportsV2/styles';
import { DropdownItem } from 'react-bootstrap';
import { setSelectedYearV2 } from '../../redux/actions/companyDashboard';
import { withTranslation } from 'react-i18next';


class ViewWbaComparison extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedYear: new Date().getFullYear(),
      selectedYearV2: new Date().getFullYear(),
    }
  }

  componentDidMount() {
    const { getUserWellbeingAssessmentDetails, selectedYearInfo, selectedYearInfoV2, getUserWellbeingAssessmentDetailsV2 } = this.props;
    getUserWellbeingAssessmentDetails(selectedYearInfo);
    getUserWellbeingAssessmentDetailsV2(selectedYearInfoV2)
  }

  updateDropdown = (year) => {
    const { setSelectedYear, getUserWellbeingAssessmentDetails } = this.props;
    setSelectedYear(year)
    getUserWellbeingAssessmentDetails(year);
  };

  updateDropdownV2 = (yearV2) => {
    const { setSelectedYearV2, getUserWellbeingAssessmentDetailsV2 } = this.props;
    setSelectedYearV2(yearV2)
    getUserWellbeingAssessmentDetailsV2(yearV2);
  };

  getWellbeingPercentage = (value) => {
    if (value) {
      return `${(value / 40) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getTotalWellbeingPercentage = (value) => {
    if (value) {
      return `${(value / 200) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getWellbeingPercentageV2 = (value) => {
    if (value) {
      return `${(value / 40) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getTotalWellbeingPercentageV2 = (value) => {
    if (value) {
      return `${(value / 200) * 100}%`
    }
    else {
      return '0%';
    }
  }

  getWellbeingAssesment = (userWbaWellbeing) => {
    const wellbeingAssesmentData = [
      {
        title: 'Lifestyle',
        fill1: this.getWellbeingPercentage(userWbaWellbeing.lifestyle),
        fillColor1: 'rgb(157, 135, 169)',
        backgroundFill1: 'rgba(157, 135, 169, 0.15)',
        imgUrl: '/public/images/userrlifestyle.png',
        width: '13px',
        label: userWbaWellbeing.lifestyle,
      },
      {
        title: 'Physical Activity',
        fill1: this.getWellbeingPercentage(userWbaWellbeing.fitness),
        fillColor1: 'rgb(105, 194, 255)',
        backgroundFill1: 'rgba(105, 194, 255, 0.15)',
        imgUrl: '/public/images/userrfitness.png',
        width: '20px',
        label: userWbaWellbeing.fitness
      },
      {
        title: 'Nutrition',
        fill1: this.getWellbeingPercentage(userWbaWellbeing.nutrition),
        fillColor1: 'rgb(159, 201, 137)',
        backgroundFill1: 'rgba(159, 201, 137, 0.15)',
        imgUrl: '/public/images/userrNutrition.png',
        width: '13px',
        label: userWbaWellbeing.nutrition
      },
      {
        title: 'Emotional Wellness',
        fill1: this.getWellbeingPercentage(userWbaWellbeing.stress),
        fillColor1: 'rgb(255, 153, 134)',
        backgroundFill1: 'rgba(255, 153, 134, 0.15)',
        imgUrl: '/public/images/userremotional_wellness.png',
        width: '13px',
        label: userWbaWellbeing.stress
      },
      {
        title: 'WorkPlace Wellness',
        fill1: this.getWellbeingPercentage(userWbaWellbeing.workplace),
        fillColor1: 'rgb(246, 180, 121)',
        backgroundFill1: 'rgba(246, 180, 121, 0.15)',
        imgUrl: '/public/images/userrworkplace.png',
        width: '21px',
        label: userWbaWellbeing.workplace
      }
    ];

    return (
      <React.Fragment>
        {wellbeingAssesmentData && wellbeingAssesmentData.length > 0 && wellbeingAssesmentData.map((item, index) => (
          <WbaPointsV2 key={index} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}>
            {item.imgUrl && <div>
              <img src={item.imgUrl} backgroundFill={item.backgroundFill1} />
            </div>}
            <div>
              <div>{this.props.t(item.title)}</div>
              <CountDiv style={{ color: '#69C2FF' }}>
                <ActivityContainer key={index} bloodPressure="5px 0 2px 0" width={'95%'}>
                  <ActivityBar fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                    <ActivityBarFill width={item.fill1} fillColor={item.fillColor1}>
                    </ActivityBarFill>
                  </ActivityBar>
                  <ShowPoints>
                    {item.label}/40
                  </ShowPoints>
                </ActivityContainer>
              </CountDiv>
            </div>
          </WbaPointsV2>
        ))}
      </React.Fragment>
    )
  }

  getTotalWellbeingAssesment = (userWbaWellbeing) => {
    const wellbeingTotalAssesmentData = [
      {
        title: 'Total',
        fill1: this.getTotalWellbeingPercentage(userWbaWellbeing.total),
        fillColor1: '#FFFFFF',
        backgroundFill1: 'rgba(255, 255, 255, 0.15)',
        // imgUrl: '/public/images/userrlifestyle.png',
        width: '13px',
        label: userWbaWellbeing.total,
      },
    ];

    return (
      <React.Fragment>
        {wellbeingTotalAssesmentData && wellbeingTotalAssesmentData.length > 0 && wellbeingTotalAssesmentData.map((item, index) => (
          <WbaPointsV2 style={{ background: '#69C2FF' }} key={index} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}>
            <div style={{ width: '25%', display: 'flex', alignItems: 'center', color: 'white', fontFamily: 'rubik-medium', fontSize: '16px', lineHeight: '20px' }}>
              <div>{this.props.t(item.title)}</div>
            </div>
            <div style={{ marginTop: '5px' }}>
              <CountDiv style={{ color: '#69C2FF' }}>
                <ActivityContainer key={index} bloodPressure="5px 0 2px 0" width={'90%'}>
                  <ActivityBar fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                    <ActivityBarFill width={item.fill1} fillColor={item.fillColor1}>
                    </ActivityBarFill>
                  </ActivityBar>
                  <ShowPoints style={{ color: 'white', fontFamily: 'rubik-medium', marginTop: '3px', marginLeft: '20px' }}>
                    {item.label}/200
                  </ShowPoints>
                </ActivityContainer>
              </CountDiv>
            </div>
          </WbaPointsV2>
        ))}
      </React.Fragment>
    )
  }

  getWellbeingAssesmentV2 = (userWbaWellbeingV2) => {
    const wellbeingAssesmentDataV2 = [
      {
        title: 'Lifestyle',
        fill1: this.getWellbeingPercentageV2(userWbaWellbeingV2.lifestyle),
        fillColor1: 'rgb(157, 135, 169)',
        backgroundFill1: 'rgba(157, 135, 169, 0.15)',
        imgUrl: '/public/images/userrlifestyle.png',
        width: '13px',
        label: userWbaWellbeingV2.lifestyle,
      },
      {
        title: 'Physical Activity',
        fill1: this.getWellbeingPercentageV2(userWbaWellbeingV2.fitness),
        fillColor1: 'rgb(105, 194, 255)',
        backgroundFill1: 'rgba(105, 194, 255, 0.15)',
        imgUrl: '/public/images/userrfitness.png',
        width: '20px',
        label: userWbaWellbeingV2.fitness
      },
      {
        title: 'Nutrition',
        fill1: this.getWellbeingPercentageV2(userWbaWellbeingV2.nutrition),
        fillColor1: 'rgb(159, 201, 137)',
        backgroundFill1: 'rgba(159, 201, 137, 0.15)',
        imgUrl: '/public/images/userrNutrition.png',
        width: '13px',
        label: userWbaWellbeingV2.nutrition
      },
      {
        title: 'Emotional Wellness',
        fill1: this.getWellbeingPercentageV2(userWbaWellbeingV2.stress),
        fillColor1: 'rgb(248, 168, 135)',
        backgroundFill1: 'rgba(248, 168, 135, 0.15)',
        imgUrl: '/public/images/userremotional_wellness.png',
        width: '13px',
        label: userWbaWellbeingV2.stress
      },
      {
        title: 'WorkPlace Wellness',
        fill1: this.getWellbeingPercentageV2(userWbaWellbeingV2.workplace),
        fillColor1: 'rgb(246, 180, 121)',
        backgroundFill1: 'rgba(246, 180, 121, 0.15)',
        imgUrl: '/public/images/userrworkplace.png',
        width: '21px',
        label: userWbaWellbeingV2.workplace
      },
    ];

    return (
      <React.Fragment>
        {wellbeingAssesmentDataV2 && wellbeingAssesmentDataV2.length > 0 && wellbeingAssesmentDataV2.map((item, index) => (
          <WbaPointsV2 key={index} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}>
            {item.imgUrl && <div>
              <img src={item.imgUrl} backgroundFill={item.backgroundFill1} />
            </div>}
            <div>
              <div>{this.props.t(item.title)}</div>
              <CountDiv style={{ color: '#69C2FF' }}>
                <ActivityContainer key={index} bloodPressure="5px 0 2px 0" width={'95%'}>
                  <ActivityBar fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                    <ActivityBarFill width={item.fill1} fillColor={item.fillColor1}>
                    </ActivityBarFill>
                  </ActivityBar>
                  <ShowPoints>
                    {item.label}/40
                  </ShowPoints>
                </ActivityContainer>
              </CountDiv>
            </div>
          </WbaPointsV2>
        ))}
      </React.Fragment>
    )
  }

  getTotalWellbeingAssesmentV2 = (userWbaWellbeingV2) => {
    const wellbeingTotalAssesmentDataV2 = [
      {
        title: 'Total',
        fill1: this.getTotalWellbeingPercentageV2(userWbaWellbeingV2.total),
        fillColor1: '#FFFFFF',
        backgroundFill1: 'rgba(255, 255, 255, 0.15)',
        // imgUrl: '/public/images/userrlifestyle.png',
        width: '13px',
        label: userWbaWellbeingV2.total,
      },
    ];

    return (
      <React.Fragment>
        {wellbeingTotalAssesmentDataV2 && wellbeingTotalAssesmentDataV2.length > 0 && wellbeingTotalAssesmentDataV2.map((item, index) => (
          <WbaPointsV2 style={{ background: '#69C2FF' }} key={index} marginRight={(index + 1) % 3 === 0 ? "0px" : "25px"}>
            <div style={{ width: '25%', display: 'flex', alignItems: 'center', color: 'white', fontFamily: 'rubik-medium', fontSize: '16px', lineHeight: '20px' }}>
              <div>{this.props.t(item.title)}</div>
            </div>
            <div style={{ marginTop: '5px' }}>
              <CountDiv style={{ color: '#69C2FF' }}>
                <ActivityContainer key={index} bloodPressure="5px 0 2px 0" width={'90%'}>
                  <ActivityBar fillColor={item.fillColor1} backgroundFill={item.backgroundFill1}>
                    <ActivityBarFill width={item.fill1} fillColor={item.fillColor1}>
                    </ActivityBarFill>
                  </ActivityBar>
                  <ShowPoints style={{ color: 'white', fontFamily: 'rubik-medium', marginTop: '3px', marginLeft: '20px' }}>
                    {item.label}/200
                  </ShowPoints>
                </ActivityContainer>
              </CountDiv>
            </div>
          </WbaPointsV2>
        ))}
      </React.Fragment>
    )

  }

  render() {
    const { showUserModal, onClose, userWbaWellbeing, selectedYearInfo, yearList, selectedYearInfoV2, userWbaWellbeingV2 } = this.props;
    return (
      <StyledModalV3 show={showUserModal} style={{ "border": "none" }}>
        <StyledHeader /*closeButton*/>
          <ContainerV2 >
            <div className="text">
              {this.props.t("My Health Risk Assessment Comparison")}
            </div>
            <div className="image">
              <img
                src={"/public/images/new_close_icon.png"}
                onClick={() => onClose()}
              />
            </div>
          </ContainerV2>
        </StyledHeader>
        <StyledBody>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '85%', display: 'flex', justifyContent: 'space-evenly' }}>
              <YearDisplay>
                <div style={{ marginRight: '10px', fontSize: '16px', color: '#9C9C9C' }}>Year</div>
                <CustomYearDropDown background caretColor style={{ width: '90px', padding: '5px 7px 7px' }}
                  type="button" title={selectedYearInfo && <div className="title" style={{ fontFamily: 'rubik-medium', color: '#0D4270' }}>{selectedYearInfo}</div>}
                  id="year"
                >
                  {
                    yearList.map((list, index) => (
                      <DropdownItem key={index} onClick={() => this.updateDropdown(list)}>{list}</DropdownItem>
                    ))
                  }
                </CustomYearDropDown>
              </YearDisplay>
              <span style={{ fontFamily: 'rubik-medium', fontSize: '18px', lineHeight: '24px', color: "#0D4270", textAlign: 'center', marginRight: '30px' }}>{this.props.t("Select years to Compare")}</span>
              <YearDisplay style={{ marginRight: '40px' }}>
                <div style={{ marginRight: '10px', fontSize: '16px', color: '#9C9C9C' }}>{this.props.t("Year")}</div>
                <CustomYearDropDown background caretColor style={{ width: '90px', padding: '5px 7px 7px' }}
                  type="button" title={selectedYearInfoV2 && <div className="title" style={{ fontFamily: 'rubik-medium', color: '#0D4270' }}>{selectedYearInfoV2}</div>}
                  id="year"
                >
                  {
                    yearList.map((list, index) => (
                      <DropdownItem key={index} onClick={() => this.updateDropdownV2(list)}>{list}</DropdownItem>
                    ))
                  }
                </CustomYearDropDown>
              </YearDisplay>
            </div>
            <div>
              <div style={{ fontFamily: 'rubik-medium', fontSize: '18px', lineHeight: '24px', color: "#0D4270", textAlign: 'center' }}>{this.props.t("Improvement")}</div>
            </div>
          </div>
          <WbaCardContainer>
            <FirstRowCard>
              <div style={{ borderRight: '1px solid rgba(156, 156, 156, 0.4)' }}>
                {this.getWellbeingAssesment(userWbaWellbeing)}
                {this.getTotalWellbeingAssesment(userWbaWellbeing)}
              </div>
            </FirstRowCard>
            <FirstRowCardV2>
              <div style={{ marginLeft: '25px', borderRight: '1px solid rgba(156, 156, 156, 0.4)' }}>
                {this.getWellbeingAssesmentV2(userWbaWellbeingV2)}
                {this.getTotalWellbeingAssesmentV2(userWbaWellbeingV2)}
              </div>
            </FirstRowCardV2>
            <LastImprovementRow>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#9D87A9' }}>{(userWbaWellbeing.lifestyle) - (userWbaWellbeingV2.lifestyle)}</div><div className='second'>{this.props.t("lifestyle")}</div></div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#69C2FF' }}>{(userWbaWellbeing.fitness) - (userWbaWellbeingV2.fitness)}</div><div className='third'>{this.props.t("Fitness")}</div></div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', color: '#9FC989' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#9FC989' }}>{(userWbaWellbeing.nutrition) - (userWbaWellbeingV2.nutrition)}</div><div className='fourth'>{this.props.t("Nutrition")}</div></div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#F8A887' }}>{(userWbaWellbeing.stress) - (userWbaWellbeingV2.stress)}</div><div className='fifth'>{this.props.t("Stress")}</div></div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '22px' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#F6B479' }}>{(userWbaWellbeing.workplace) - (userWbaWellbeingV2.workplace)}</div><div className='sixth'>{this.props.t("Workplace")}</div></div>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', background: "rgb(105, 194, 255)", borderRadius: '6px', height: '80px', alignItems: 'center', width: '129px', marginLeft: '25px' }}><div style={{ fontSize: '18px', marginRight: '5px', color: '#ffffff' }}>{(userWbaWellbeing.total) - (userWbaWellbeingV2.total)}</div><div className='seventh'>{this.props.t("Total")}</div></div>
            </LastImprovementRow>
          </WbaCardContainer>
        </StyledBody>
      </StyledModalV3>
    );
  }
}

ViewWbaComparison.propTypes = {
  showUserModal: PropTypes.bool,
  onClose: PropTypes.func,
  getUserWellbeingAssessmentDetails: PropTypes.func,
  userWbaWellbeing: PropTypes.object,
  setSelectedYear: PropTypes.func,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYearV2: PropTypes.func,
  selectedYearInfoV2: PropTypes.number,
  getUserWellbeingAssessmentDetailsV2: PropTypes.func,
  userWbaWellbeingV2: PropTypes.object,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  userWbaWellbeing: state.profileData.userWbaWellbeing,
  userWbaWellbeingV2: state.profileData.userWbaWellbeingV2,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  selectedYearInfoV2: state.companyDashboard.selectedYearInfoV2
});


const mapDispatchToProps = (dispatch) => ({
  getUserWellbeingAssessmentDetails: (year) => dispatch(getUserWellbeingAssessmentDetails(year)),
  getUserWellbeingAssessmentDetailsV2: (yearV2) => dispatch(getUserWellbeingAssessmentDetailsV2(yearV2)),
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  setSelectedYearV2: (yearV2) => dispatch(setSelectedYearV2(yearV2))
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(ViewWbaComparison));