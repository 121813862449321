import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { educationAdventure, ImageUrl } from '../../utils/constants';
import moment from 'moment';
import { commaSeperator, convertMilesToKm } from '../../utils/methods';
import { CountDiv, InitativeCard, NewTotalPoints, NewTotalPointsV2, WellnessCard } from './styles';
import { fetchAdventureScoreBoard, getChallengeParticipation } from '../../redux/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import LazyImage from '../common/LazyImage/LazyImage';
// import {getLastSyncDetails} from "../../utils/methods";


const scoresDetails = [
  {
    color: '#69C2FF',
    width: '100%',
    value: 20,
    content: 'out of 30 lifestyle adventures completed',
    image: 'fitnessvideow.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 10,
    content: 'out of 25 readings completed',
    image: 'recipevideow.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'out of 25 quizzes completed',
    image: 'ReadingCompletedn.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'out of 25 quizzes completed',
    image: 'educationNew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'Daily Tips Seen',
    image: 'liveEvents.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'Daily Tip Points Earned',
    image: 'onDemandEvents.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 12,
    content: 'perfect score on the quizzes',
    image: 'tip.png',
    overlap: false
  },
];

class WellnessWellbeing extends Component {
  constructor() {
    super();
    this.state = {
      scoresDetails: null,
    }
  }

  componentDidMount = () => {
    const { adventureScoreBoard, fetchAdventureScoreBoard, getChallengeParticipation } = this.props;
    if (!adventureScoreBoard) {
      fetchAdventureScoreBoard();
    }
    else {
      this.setScore(adventureScoreBoard);
    }
    const currentYear = new Date().getFullYear();
    const year = currentYear;
    getChallengeParticipation(year)
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { adventureScoreBoard/*, fetchAdventureScoreBoard*/ } = nextProps;
    if (adventureScoreBoard) {
      this.setScore(adventureScoreBoard);
    } 
    // else {
    //   fetchAdventureScoreBoard();
    // }
  }

  setScore = (adventureScoreBoard) => {
    let arr = [...scoresDetails];
    for (let i = 0; i < arr.length; i++) {
      switch (i) {
      case 0:
        arr[i].value = adventureScoreBoard.fitness_videos_count;
        arr[i].content = `${this.props.t('Fitness Videos Watched')}`;
        break;
      case 1:
        arr[i].value = adventureScoreBoard.recipe_videos_count;
        arr[i].content = `${this.props.t('Recipe Videos Watched')}`;
        break;
      case 2:
        arr[i].value = adventureScoreBoard.read_journeys_count;
        arr[i].content = `${this.props.t('Article Reading Completed')}`;
        break;
      case 3:
        arr[i].value = adventureScoreBoard.completed_quizzes_count;
        arr[i].content = `${this.props.t('Total Quizes Passed')}`;
        break;
      case 4:
        arr[i].value = adventureScoreBoard.recipe_videos_point;
        arr[i].content = `${this.props.t('Live Events Attended')}`;
        break;
      case 5:
        arr[i].value = adventureScoreBoard.fitness_videos_count;
        arr[i].content = `${this.props.t('On-demand Events Watched')}`;
        break;
      case 6:
        arr[i].value = adventureScoreBoard.daily_tip_seen;
        arr[i].content = `${this.props.t('Daily Tip Viewed')}`;
        break;
      }
    }
    this.setState({
      scoresDetails: arr
    })
  }

  render() {
    const { dailyActivityData, workoutActivityCount, history, participantCount } = this.props;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    let Total = participantCount.behavior_challenge_count + participantCount.classic_challenge_count + participantCount.group_challenge_count + participantCount.survivor_challenge_count
    return (
      <div>
        <InitativeCard>
          <div className='company-initative'>
            <div className='heading'>
              {this.props.t("My")} {moment().format('YYYY')} {this.props.t("Fitness Activity")} &nbsp;
            </div>
            <hr className='horizontal-line2' />
            <div className='fitness-wrapper'>
              <NewTotalPoints>
                <LazyImage src={ImageUrl + "/images/behaviorChallenges/RunSteps.png"}/>
                <div>
                  <div>{this.props.t("Total Steps Walked")}</div>
                  <CountDiv style={{ color: '#32DBC6' }}>
                    {_.isUndefined(dailyActivityData.steps) ? 0 : commaSeperator(parseInt(dailyActivityData.steps,10))}
                    <span>&nbsp;&nbsp;{this.props.t("Steps")}</span>
                  </CountDiv>
                </div>
              </NewTotalPoints>
              <NewTotalPoints>
                <LazyImage src={ImageUrl + "/images/behaviorChallenges/Calories.png"}/>
                <div>
                  <div>{this.props.t("Total Calories Burned")} </div>
                  <CountDiv style={{ color: '#F6B479' }}>
                    {_.isUndefined(dailyActivityData.calories) ? 0 : commaSeperator(parseInt(dailyActivityData.calories,10))}
                    <span>&nbsp;&nbsp;{this.props.t("Calories")}</span>
                  </CountDiv>
                </div>
              </NewTotalPoints>
              <NewTotalPoints>
                <LazyImage src={ImageUrl + "/images/behaviorChallenges/distanceNew.png"}/>
                <div>
                  <div>{this.props.t("Distance Traveled")}</div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    {_.isUndefined(dailyActivityData.distance) ? 0 : DistanceUnit === "KILOMETER" ? commaSeperator(convertMilesToKm(dailyActivityData.distance)) : commaSeperator(parseInt(dailyActivityData.distance,10))}
                    <span>&nbsp;&nbsp;{DistanceUnit === "KILOMETER" ? "Km" : this.props.t("Miles")}</span>
                  </CountDiv>
                </div>
              </NewTotalPoints>
            </div>
          </div>
        </InitativeCard>
        {workoutActivityCount && workoutActivityCount.workout_details.length > 0 ? <WellnessCard>
          <div className='wellness-interest'>
            <div className='wellness-title'>
              <span className='title-text'>{this.props.t("My")} {moment().format('YYYY')} {this.props.t("Workout History")}</span>
              <span className='wellness-count'>{`(${this.props.t("Total")} : ${workoutActivityCount.workout_details.length})`}</span>
              <button onClick={() => history.push('/workout-history')} className='user-edit-btn'>{this.props.t("View Details")}</button>
            </div>
            <hr className='horizontal-line' />
            <div className='interest-card-wrapper'>
              {workoutActivityCount.workout_details.length > 0 && workoutActivityCount.workout_details.map((data, index) =>
                (
                  <NewTotalPointsV2 key={index} marginRight={(index + 1) % 4 === 0 ? "0px" : "25px"}>
                    <div>
                      <img style={{padding: '7px'}} src={`${ImageUrl}/${data.interest_icon}`} />
                    </div>
                    <div>
                      <div>{this.props.t(data.activity)}</div>
                      <CountDiv style={{ color: '#69C2FF' }}>
                        <div>{this.props.t(data.activity_count)}</div>
                      </CountDiv>
                    </div>
                  </NewTotalPointsV2>
                ))
              }
            </div>
          </div>
        </WellnessCard> : null}
        <InitativeCard>
          <div className='company-initative'>
            <div className='heading'>
              {this.props.t("My")} {moment().format('YYYY')} {this.props.t("Wellness Activities")} &nbsp;
            </div>
            <hr className='horizontal-line2' />
            <div className='fitness-wrapper'>
              {scoresDetails && scoresDetails.length > 0 && scoresDetails.map((data, index) => (
                <NewTotalPoints key={index}>
                  <div>
                    <img src={educationAdventure + data.image} />
                  </div>
                  <div>
                    <div>{this.props.t(data.content)}</div>
                    <CountDiv style={{ color: '#69C2FF' }}>
                      {this.props.t(data.value)}
                    </CountDiv>
                  </div>
                </NewTotalPoints>))}
            </div>
          </div>
        </InitativeCard>
        <InitativeCard>
          <div className='company-initative'>
            <div className='heading'>
              {this.props.t("My")} {moment().format('YYYY')} {this.props.t("Challenge Participation")} &nbsp;
              <span className='wellness-count' style={{color: '#FD7175', marginLeft: '20px'}}>{`(${this.props.t("Total")} : ${Total} ${this.props.t("Challenges")})`}</span>
            </div>
            <hr className='horizontal-line2' />
            <div className='fitness-wrapper'>
              <NewTotalPoints>
                <div>
                  <img src="/public/images/behaviorChallenges/wellness_challenge_count.png" />
                </div>
                <div>
                  <div>{this.props.t("Wellness Challenges Joined")}</div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    {participantCount.behavior_challenge_count}
                  </CountDiv>
                </div>
              </NewTotalPoints>
              <NewTotalPoints>
                <div>
                  <img src="/public/images/behaviorChallenges/classic_challenge_count.png" />
                </div>
                <div>
                  <div>{this.props.t("Classic Challenge Joined")} </div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    {participantCount.classic_challenge_count} 
                  </CountDiv>
                </div>
              </NewTotalPoints>
              <NewTotalPoints>
                <div>
                  <img src="/public/images/behaviorChallenges/survivor_challenge_count.png" />
                </div>
                <div>
                  <div>{this.props.t("Survivor Challenge Joined")}</div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    {participantCount.survivor_challenge_count}
                  </CountDiv>
                </div>
              </NewTotalPoints>
              <NewTotalPoints>
                <div>
                  <img src="/public/images/behaviorChallenges/group_challenge_count.png" />
                </div>
                <div>
                  <div>{this.props.t("Group Challenge Joined")}</div>
                  <CountDiv style={{ color: '#69C2FF' }}>
                    {participantCount.group_challenge_count}
                  </CountDiv>
                </div>
              </NewTotalPoints>
            </div>
          </div>
        </InitativeCard>
      </div>
    )
  }
}
WellnessWellbeing.propTypes = {
  userModal: PropTypes.func,
  user: PropTypes.object,
  interest: PropTypes.object,
  dailyActivityData: PropTypes.object,
  userRecognitionCount: PropTypes.array,
  history: PropTypes.object.isRequired,
  workoutActivityCount: PropTypes.object,
  adventureScoreBoard: PropTypes.object,
  fetchAdventureScoreBoard: PropTypes.func.isRequired,
  getChallengeParticipation: PropTypes.object,
  participantCount: PropTypes.object,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  adventureScoreBoard: state.education.adventureScoreBoard,
  participantCount: state.challenges.participantCount
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdventureScoreBoard: () => dispatch(fetchAdventureScoreBoard()),
  getChallengeParticipation: (year) => dispatch(getChallengeParticipation(year))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WellnessWellbeing));